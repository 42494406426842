import { mapApiImageToImageProps } from './common.mappers';

import type { FHomepageTemplateProps } from '@/components/templates/FHomepageTemplate.vue';

export function mapApiDataToHomepage(
  homepageRawData: StrapiResponseData<HomepageContentType> | undefined
): FHomepageTemplateProps {
  return {
    blogPostCards: (
      homepageRawData?.attributes.explore_section?.blog_posts?.data ?? []
    ).map(card => ({
      text: card.attributes?.title ?? '',
      image: mapApiImageToImageProps(
        card.attributes?.main_image?.data?.attributes,
        'medium'
      ),
      link: { location: `/resources/blog/${card.attributes?.slug}` },
      linkText: '',
    })),
    lightOnCards: (
      homepageRawData?.attributes.featured_use_cases?.data ?? []
    ).map(useCase => ({
      title: `${useCase.attributes?.city_name} - ${useCase.attributes?.service_name}`,
      text: useCase.attributes?.service_description ?? '',
      location:
        useCase.attributes?.redirect_url ??
        `/case-studies/${useCase.attributes?.slug}`,
      image: {
        ...mapApiImageToImageProps(
          useCase.attributes?.bike_image?.data?.attributes
        ),
        width: 150,
        height: 260,
      },
    })),
  };
}
